import React from 'react';

function About() {
  return (
    <section id="about">
      <h2>About Me</h2>
      <p>
        I have over 2 years of experience as a Full Stack Developer, specializing in technologies like React, Node.js, and Google Cloud.
      </p>
    </section>
  );
}

export default About;