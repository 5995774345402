import React from 'react';

function Projects() {
  return (
    <section id="projects">
      <h2>My Projects</h2>
      <ul>
        <li><strong>E-Commerce Web App</strong> - Built with React, Node.js, MongoDB, and deployed on Google Cloud.</li>
        <li><strong>Portfolio Website</strong> - The current portfolio built using React.</li>
      </ul>
    </section>
  );
}

export default Projects;