// Home.js
import React from 'react';

function Home() {
  return (
    <section id="home">
      <h1>Welcome to My Portfolio</h1>
      <p>I am Darren Horton, a passionate Full Stack Developer.</p>
    </section>
  );
}

export default Home;
